<template>
  <div class="no-game">
    <v-container class="tw-flex tw-flex-col tw-justify-center tw-h-full tw-p-4 sm:tw-p-6">
      <div class="no-game__description tw-text-center">
        {{ $t('noGame.description') }}
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'NoGame',
}
</script>

<style lang="scss" scoped>
.no-game {
  background-color: var(--theme-game-background);
  height: 100%;
  color: var(--theme-game-color);
}
</style>
